import { FETCH_AD_VOD, FETCH_AD_VOD_SUCCESS, FETCH_AD_VOD_FAILURE, CLEAR_AD_VOD } from "../actions/ad.action";
import { CLEAR, ERROR, SUCCESS, LOADING } from "constant/status";
import { produce } from "immer";

const INITIAL_STATE = {
    status: CLEAR,
    error: false,
    data: [],
    items: [],
};

const adVodReducer = produce((state, { type, payload, placement, creatives }) => {
    switch (type) {
        case FETCH_AD_VOD:
            state.status = LOADING;
            return;

        case CLEAR_AD_VOD:
            return INITIAL_STATE;

        case FETCH_AD_VOD_SUCCESS:
            const adData = placement ? { ...creatives, code: placement } : {};
            state.status = SUCCESS;
            state.data = payload;
            state.items = [adData];
            return;

        case FETCH_AD_VOD_FAILURE:
            state.status = ERROR;
            state.error = payload;
            return;

        default:
            return;
    }
}, INITIAL_STATE);

export default adVodReducer;