import { handleFetchErrors, handleFailedToFetch, getXmlTagValue } from "../helpers";
import { DEAPI_DOMAIN } from "constant/config";

export const FETCH_AD = "FETCH_AD";
export const FETCH_AD_SUCCESS = "FETCH_AD_SUCCESS";
export const FETCH_AD_FAILURE = "FETCH_AD_FAILURE";
export const CLEAR_AD = "CLEAR_AD";

 // VOD 광고 전용 액션 추가
export const FETCH_AD_VOD = "FETCH_AD_VOD"; 
export const FETCH_AD_VOD_SUCCESS = "FETCH_AD_VOD_SUCCESS";
export const FETCH_AD_VOD_FAILURE = "FETCH_AD_VOD_FAILURE";
export const CLEAR_AD_VOD = "CLEAR_AD_VOD";

// 광고API 호출
export function fetchAd(params, code, timeout) {
    return (dispatch) => {
        const isVod = code === "CHANNELLIST"; // VOD 광고인지 여부

        dispatch({ type: isVod ? FETCH_AD_VOD : FETCH_AD });

        const controller = new AbortController();
        const { signal } = controller;

        const fetchRequest = fetch(`${DEAPI_DOMAIN}/api/v1/recommend?${params}`, {
            method: "GET",
            credentials: "include",
            signal,
        });

        // 타임아웃 Promise
        const timeoutPromise = new Promise((_, reject) => {
            if (timeout) {
                setTimeout(() => {
                    controller.abort();
                    reject(new Error("Request timed out"));
                }, timeout);
            }
        });

        // fetch 요청과 타임아웃을 합침
        Promise.race([fetchRequest, timeoutPromise])
            .then(handleFetchErrors, handleFailedToFetch)
            .then((response) => response.json())
            .then((response) => {
                if (response.exist) {
                    if (response.type === "ERROR") {
                        dispatch({
                            type: isVod ? FETCH_AD_VOD_FAILURE : FETCH_AD_FAILURE,
                            payload: response,
                        });
                    } else {
                        let creatives = {};
                        if (response.type !== "SDK") {
                            creatives = response.creatives.find(
                                (element) => element.impression_url !== ""
                            ) || {};

                            let impressionUrl = "";

                            response.creatives?.forEach((element) => {
                                // 동영상 광고는 vast 내부에 xml 형태로 들어가있다;; 파싱해서 써야 함
                                if (element.vast) {
                                    impressionUrl = getXmlTagValue("Impression", element.vast)[0]?.value || '';
                                }
                            });

                            if (!impressionUrl) {
                                // creatives에서 impression_url은 없고 impression_urls만 있는 경우 처리
                                impressionUrl =
                                    creatives.impression_url !== undefined
                                        ? creatives.impression_url
                                        : creatives.impression_urls?.[0];
                            }

                            adSetlog(impressionUrl);
                        }
                        dispatch({
                            type: isVod ? FETCH_AD_VOD_SUCCESS : FETCH_AD_SUCCESS,
                            placement: code,
                            creatives: creatives,
                            payload: response,
                        });
                    }
                } else {
                    dispatch({
                        type: isVod ? FETCH_AD_VOD_FAILURE : FETCH_AD_FAILURE,
                        payload: response,
                    });
                }
            })
            .catch((error) => {
                if (error.name === "AbortError") {
                    console.log("타임아웃 에러");
                }
                dispatch({
                    type: isVod ? FETCH_AD_VOD_FAILURE : FETCH_AD_FAILURE,
                    payload: error ? error : { code: 0, message: error },
                });
            });
    };
}

// 광고 통계 호출
export function adSetlog(url) {
    if (typeof url !== "undefined" && url !== "") {
        fetch(url, {
            credentials: "include",
        }).then(() => {});
    }
}