import classNames from "classnames";
import compareVersions from "compare-versions";
import i18next from "i18next";
import React, { Component } from "react";
import { isAndroid, isIOS, isMobile, osVersion, mobileModel } from "react-device-detect";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { parse } from "url";
import AdballoonEventLayer from "components/event/AdballoonEventLayer";
import { destroyFavorite, fetchStationInfo, storeFavorite, storeFavoritePush, destroyFavoritePush } from "actions/station.action";
import { Medal } from "components/contents/Medal";
import { SMR } from "constant/maintype";
import { getRealname, goGiftAdBalloon, goGiftStarBalloon, goLogin, 
    isWebview, sendNote, setClickLogapi, popupSubscription, getCeremony, getCeremonyImg, handleCeremonyImgError,
    getAppVersion, inAppPlayStore, inAppStore } from "helpers";
import GiftEvent from "pages/station/home/event/GiftEvent";
import { fetchDrawalUserInfo } from "actions/userWithDrawal.action";
import { toastr } from "components/toastr";
import { APP_SCHEME, MOBILE_DOMAIN, RES_DOMAIN } from "constant/config";

class ChannelArt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowGift: false,
            isShowVersion: false,
            isActiveEvent: props.isActiveEvent,
            adballoonEvent: props.adballoonEvent,
            isEventModal: false,
            modalActionName: '',
            modalImageUrl : '',
            fanclub_order: 0,
            gift_count: 0,
            channelTitleImage: ''
        };
        this.STARBALLOON = "STARBALLOON";
        this.ADBALLOON = "ADBALLOON";
    }

    componentDidMount() {
        const version = getAppVersion();
        if (compareVersions(version, "6.3.0") >= 0) {
            this.setState({
                isShowVersion: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 타이틀 이미지 셋팅
        if (this.state.channelTitleImage === '' && this.props.bjInfo?.channelart) {
            const { bjInfo } = this.props;
            const { channelart } = bjInfo;

            if (channelart) {
                try {
                    this.handleTitleImageLoad(channelart?.pc_url);
                } catch(error) {
                    this.setState({
                        channelTitleImage: `${RES_DOMAIN}/images/bj/img_ch-default.png`
                    });
                }

            }
        }
    }

    handleFavorite = () => {
        const { stationInfo = {}, authenticationReducer } = this.props;
        const { data = {} } = stationInfo;
        const { is_favorite = false } = data;
        const { is_login } = authenticationReducer;
        if (!is_login) {
            goLogin();
            return;
        }

        if (is_favorite) {
            this.props.destroyFavorite();
            setClickLogapi("station_click", {button_group : "station_info", button_type : "favorite", action_type : "delete"});
        } else {
            this.props.storeFavorite();
            setClickLogapi("station_click", {button_group : "station_info", button_type : "favorite", action_type : "add"});
        }
    };

    handleVisibilityClose = () => {
        this.setState({
            modalActionName: '',
        });
    }

    // 별풍선 애드벌룬 세레머니 모달 action
    handleVisibilityState = async (openName) => {

        const { isActiveEvent } = this.props;
        if(openName === "ADBALLOON" && isActiveEvent) {
            (async() => {
                const response = await getCeremony(openName);
                if (response.data !== null) {
                    this.setState({
                        fanclub_order: response.data.fanclub_order ,
                        gift_count : response.data.gift_count,
                        
                    });
                    const oCeremony = {
                        giftType: openName,
                        giftCnt: response.data.gift_count,
                        ceremony_starballoon: response.ceremony_starballoon,
                        ceremony_adballon_img : response.ceremony_adballon_img
                    }

                    const returnData = getCeremonyImg(oCeremony);
                    this.setState({
                        modalImageUrl: returnData.imgUrl
                    })
                    this.handleOpenEventModal();
                }
            })();
            return false;
        }

        if (document.visibilityState === 'visible' && !this.state[openName]) {
            (async() => {
                const response = await getCeremony(openName);
                if (response.data !== null) {
                    this.setState({
                        modalActionName: openName,
                        //[openName] : true,
                        fanclub_order: response.data.fanclub_order,
                        gift_count : response.data.gift_count
                    });

                    const oCeremony = {
                        giftType: openName,
                        giftCnt: response.data.gift_count,
                        ceremony_starballoon: response.ceremony_starballoon,
                        ceremony_adballon_img : response.ceremony_adballon_img
                    }


                    const returnData = getCeremonyImg(oCeremony);
                    this.setState({
                        modalImageUrl: returnData.imgUrl
                    })
                }
            })();
        }
    }

    /**
     * 별풍선/애드벌룬 세레머니 모달 PC(window.popup) / App(visibilitychange event) 처리
     * @param {} openPopupId window.popup return id
     * @param {*} openName Modal open state name
     */
    handleOpenModal = (openPopupId, openName) => {
        this.setState({
            fanclub_order: 0,
            gift_count: 0,
        });

        if (openPopupId) {
            const timer = setInterval(() => {
                if (openPopupId.closed) {
                    clearInterval(timer);
                    this.handleVisibilityState(openName);
                }
            }, 1000);
        } else {
            document.addEventListener("visibilitychange", 
                () => this.handleVisibilityState(openName));
        }
    }

    handleOpenEventModal = () => {
        this.setState({
            isEventModal: true,
            isActiveEvent: true
        });
    }

    handleModalClose = () => {
        this.setState({
            isEventModal: false,
        });
        setTimeout(() => {
            this.setState({
                gift_count: 0,
            });
        }, 350);

    }


    // 별풍선 후원하기 / 선물하기 click event
    handleGiftStarballoon = () => {
        const { authenticationReducer } = this.props;
        const { is_login, is_realname_check, is_abroad_join } = authenticationReducer;
        if (!is_login) {
            goLogin();
        } else if (!is_realname_check && !is_abroad_join) {
            getRealname();
        } else {
            (async () => {
                let result = await this.withDrawCheck();
    
                if(!result) {
                    return false;
                }
                const openPopupId = goGiftStarBalloon(global.bj_id);
                this.handleOpenModal(openPopupId, this.STARBALLOON);
            })();
        }
    };

    // 애드벌룬 후원하기 / 선물하기 click event
    handleGiftAdBalloon = () => {
        const { authenticationReducer, isActiveEvent } = this.props;
        const { is_login } = authenticationReducer;
        if (!is_login) {
            goLogin();
        } else {
            (async () => {
                let result = await this.withDrawCheck();
    
                if(!result) {
                    return false;
                }
    
                if(isActiveEvent) {
                    this.handleOpenEventModal();
                    return false;
                }else {
                    // 애드벌룬 세레머니 추가
                    const adballonPopupId = goGiftAdBalloon(global.bj_id);
                    this.handleOpenModal(adballonPopupId, this.ADBALLOON);
                }
            })();
        }

        
    };

    handleGiftSticker = () => {
        const { authenticationReducer } = this.props;
        const { is_login, is_realname_check, is_abroad_join } = authenticationReducer;
        if (!is_login) {
            goLogin();
        } else if (!is_realname_check && !is_abroad_join) {
            getRealname();
        } else {
            (async () => {
                let result = await this.withDrawCheck();
    
                if(!result) {
                    return false;
                }

                let url = `${MOBILE_DOMAIN}/item/a/stickergift?szBjId=${global.bj_id}&r=${Math.random()}`;
                
                if (isWebview && isAndroid) {
                    /**
                     * 스티커 구글플레이 인앱 결제 isPlayStore header 추가
                     * 안드로이드 특정 웹뷰(ex.afreeca://browser/external) 호출 시 userAgent 값이 정상 전달 안되는 이슈 있음
                     * 파라미터로 앱 버전 추가해서 전달
                     */
    
                    const isPlayStore = inAppPlayStore();
                    const version = getAppVersion();
                    const store = inAppStore();
                    // url = "https:" + url; // ios에서 afreeca: 스킴 사용 시 http/https 스킴이 없을 경우 동작하지 않음
                    url += `&ref=webview`;            
                    url += '&isPlayStore=' + isPlayStore;
                    url += '&appVersion=' + (compareVersions(version, "1.0.0") >= 0 ? version : 0);
                    
                    if(store) {
                        url += '&store=' + store;
                    }
    
                    url += '&szOsVer=' + osVersion;
                    url += '&szModel=' + mobileModel;
                    window.location.href = `${APP_SCHEME}browser/external?url=${encodeURIComponent(url)}`;
                } else {
                    window.open(url);
                }
            })();
        }
    };

    /**
     * 구독하기
     */
    handleGiftSubscription = async () => {
        const { authenticationReducer } = this.props;
        const { is_login, is_realname_check, is_abroad_join } = authenticationReducer;
        if (!is_login) {
            goLogin();
        } else if (!is_realname_check && !is_abroad_join) {
            getRealname();
        } else {
            (async () => {
                try {
                    let result = await this.withDrawCheck();
    
                    if(!result) {
                        return false;
                    }
    
                    popupSubscription();
                    this.props.fetchStationInfo(global.bj_id);
                } catch (error) {
                    if (error.message) {
                        alert(error.message);
                    }
                }
            })();
        }
    };

    handleShowGifts = (flag) => (e) => {
        this.setState({
            isShowGift: flag,
        });
    };

    getDefaultLinkName = (type) => {
        switch (type) {
            case 1:
                return i18next.t("페이스북");
            case 2:
                return i18next.t("인스타그램");
            case 3:
                return i18next.t("유튜브");
            case 4:
                return i18next.t("네이버 카페");
            case 5:
                return i18next.t("SOOP");
            case 9:
                return i18next.t("기타 외부링크");
            default:
                return "";
        }
    };

    getLinkName = (link) => {
        if (link.link_name) {
            return i18next.t(link.link_name);
        } else {
            this.getDefaultLinkName(link.type);
        }
    };

    handleLinkClick = (link) => (e) => {
        const url = parse(link.url);
        if (url) {
            setClickLogapi("station_link", {
                link_code: link.code,
                lk_ht: url.hostname,
                bj: global.bj_id,
            });
            window.open(link.url);
        }
    };

    handleFavoritePush = () => {
        const { stationInfo = {}, authenticationReducer } = this.props;
        const { data = {} } = stationInfo;
        const { is_mobile_push = false } = data;
        const { is_login } = authenticationReducer;
        if (!is_login) {
            goLogin();
            return;
        }

        if (is_mobile_push) {
            this.props.destroyFavoritePush();
            setClickLogapi("station_click", {button_group : "station_info", button_type : "fav_alarm", action_type : "off"});
        } else {
            this.props.storeFavoritePush();
            setClickLogapi("station_click", {button_group : "station_info", button_type : "fav_alarm", action_type : "on"});
        }
    };

    withDrawCheck = async() => {
        let withDraw = await fetchDrawalUserInfo(); 
        
        if(withDraw) {
            let message = i18next.t("현재 탈퇴 처리중인 유저입니다.") + "\n" ;
            message += i18next.t("후원할 수 없습니다.");
            toastr.success(message, {
                timeOut: 2000,
            });
            return false;
        }

        return true;
    };

    handleTitleImageLoad = (imageUrl) => {
        const image = new Image();

        image.onload = () => {
            this.setState({
                channelTitleImage: imageUrl
            })
        }

        image.onerror = () => {
            this.setState({
                channelTitleImage: `${RES_DOMAIN}/images/bj/img_ch-default.png`
            })
        }
        image.src = imageUrl;
    }

    render() {
        const { isShowGift, isShowVersion } = this.state;
        const { bjInfo, stationInfo = {} , isActiveEvent, adballoonEvent } = this.props;
        const { medals, links, channelart } = bjInfo;
        const { data = {} } = stationInfo;
        const { is_owner, is_subscription, is_favorite, is_mobile_push, subscribe_visible, station } = data;
        const { display, user_nick } = station;
        const { main_type } = display;
        const lang = document.documentElement.lang;
        
        let sectionStyle = null;

        if (isMobile) {
            if (channelart) {
                sectionStyle = {
                    backgroundImage: `url(${this.state.channelTitleImage})`,
                    backgroundRepeat: "repeat-x",
                    backgroundSize: "auto 100%",
                    backgroundPosition: "50% 0",
                };
            }

            return (
                <section className="ch-art" style={sectionStyle}>
                    <div className="util_set">
                        <div className="btn-base">
                            {is_owner === false && (
                                <React.Fragment>
                                    {is_favorite && (
                                        <button
                                            type="button"
                                            className={classNames("btn-alarm", { on: is_mobile_push })}
                                            onClick={this.handleFavoritePush}
                                        >
                                            알림
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className={classNames("btn-favor", { on: is_favorite })}
                                        onClick={this.handleFavorite}
                                    >
                                        즐겨찾기
                                    </button>
                                    {/**기존에는 안드로이드만 지원하다 iOS도 구독 페이지 지원 */}
                                    {isWebview && isShowVersion && main_type !== SMR && (
                                        <button
                                            type="button"
                                            className={classNames("btn-subscribe", { on: is_subscription })}
                                            onClick={() => {
                                                this.handleGiftSubscription()
                                                setClickLogapi("station_click", {button_group : "station_info", button_type : "sub"})
                                            }}
                                        >
                                            구독
                                        </button>
                                    )}
                                    <button type="button" className="btn-msg" onClick={() => sendNote(global.bj_id)}>
                                        쪽지 보내기
                                    </button>
                                    {/* {isIOS && isWebview && lang === "ko" && main_type !== SMR && (
                                        <button type="button" className="btn-adballoon" onClick={this.handleGiftAdBalloon}>
                                            애드벌룬
                                        </button>
                                    )} */}
                                </React.Fragment>
                            )}
                            {((subscribe_visible !== "off" && isIOS) || isAndroid) && main_type !== SMR && (
                                <button type="button" className="btn-gift" onClick={this.handleShowGifts(true)}>
                                    선물하기
                                </button>
                            )}
                        </div>
                        {isShowGift && (
                            <div className="btn-gifts">
                                {is_owner === false && (
                                    <>
                                    <button type="button" className="btn-balloon" 
                                        onClick={() => {
                                            this.handleGiftStarballoon()
                                            setClickLogapi("station_click", {button_group : "station_info", button_type : "starballoon"})
                                        }}>
                                        별풍선
                                    </button>
                                    <Modal
                                        open={this.state.modalActionName === this.STARBALLOON}
                                        onClose={this.handleVisibilityClose}
                                        showCloseIcon={false}
                                        styles={{
                                            modal: {
                                                maxWidth: "unset",
                                            },
                                            overlay: {
                                                background: "unset",
                                            }
                                        }}
                                    >
                                        {(
                                            <div className="balloonGift_layer">
                                                <div className="inner">
                                                <div className="box star">
                                                    <span className="img"><img src={this.state.modalImageUrl} alt="" onError={(e) => handleCeremonyImgError(e, this.STARBALLOON, this.state.gift_count)}/></span>
                                                    <div className="text">
                                                        <strong><em>{user_nick}</em> 님에게<br />별풍선 {this.state.gift_count}개 선물!</strong>
                                                        { this.state.fanclub_order > 0 && (
                                                            <p className="fan">{this.state.fanclub_order}번째로 팬클럽이 되신걸<br /> 축하드립니다!</p>
                                                        )}
                                                    </div>
                                                    <button type="button" className="close" onClick={this.handleVisibilityClose}>레이어 닫기</button>
                                                </div>
                                            </div></div>
                                        )}
                                        
                                    </Modal>
                                    </>   
                                )}
                                {is_owner === false && lang === "ko" && (
                                    <>
                                    <button type="button" className="btn-adballoon" 
                                        onClick={() => { 
                                            this.handleGiftAdBalloon()
                                            setClickLogapi("station_click", {button_group : "station_info", button_type : "adballoon"})
                                        }} >
                                        애드벌룬
                                    </button>
                                    <Modal
                                        open={this.state.modalActionName === this.ADBALLOON}
                                        onClose={this.handleVisibilityClose}
                                        showCloseIcon={false}
                                        styles={{
                                            modal: {
                                                maxWidth: "unset",
                                            },
                                            overlay: {
                                                background: "unset",
                                            }
                                        }}
                                    >
                                        {(
                                            <div className="balloonGift_layer">
                                                <div className="inner">
                                                <div className="box">
                                                    <span className="img"><img src={this.state.modalImageUrl} alt="" onError={(e) => handleCeremonyImgError(e, this.ADBALLOON, this.state.gift_count)}/></span>
                                                    <div className="text">
                                                        <strong><em>{user_nick}</em> 님에게<br />애드벌룬 {this.state.gift_count}개 선물!</strong>
                                                        { this.state.fanclub_order > 0 && (
                                                            <p className="fan">{this.state.fanclub_order}번째로 팬클럽이 되신걸<br /> 축하드립니다!</p>
                                                        )}
                                                    </div>
                                                    <button type="button" className="close" onClick={this.handleVisibilityClose}>레이어 닫기</button>
                                                </div>
                                            </div></div>
                                        )}
                                        
                                    </Modal>
                                    </>
                                    
                                 
                                    
                                )}
                                {!(isIOS && isWebview && !isShowVersion) && (
                                        <button type="button" className="btn-stiker" 
                                        onClick={() => {
                                            this.handleGiftSticker()
                                            setClickLogapi("station_click", {button_group : "station_info", button_type : "sticker"})
                                        }}>
                                            스티커
                                        </button>
                                    )
                                }
                                
                                <button type="button" className="btn-close" onClick={this.handleShowGifts(false)}>
                                    닫기
                                </button>
                            </div>
                        )}
                        { lang === "ko" && isActiveEvent && (
                            <>
                            <GiftEvent onOpen={this.state.isEventModal} percent={adballoonEvent.percent} onOpenModal={this.handleOpenModal} channelArt={true} />
                            <Modal
                                open={this.state.isEventModal || this.state.modalActionName === this.ADBALLOON} 
                                onClose={this.handleModalClose}
                                showCloseIcon={false}
                                classNames={
                                    {modal: "adballonLayerBottom"}}
                            >
                                <div>
                                    <AdballoonEventLayer onClose={this.handleModalClose} onOpenModal={this.handleOpenModal} gift={this.state.gift_count > 0 ? {gift_cnt: this.state.gift_count, fanclub_order: this.state.fanclub_order, gift_img: this.state.modalImageUrl} : null} />
                                </div>
                                                                            
                            </Modal>
                            </>
                        )}
                    </div>
                    <Medal medals={medals} />
                </section>
            );
        } else {
            if (channelart) {
                sectionStyle = {
                    backgroundImage: `url(${this.state.channelTitleImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "932px 187px",
                };
            }
            return (
                <section className="ch-art" style={sectionStyle}>
                    <Medal medals={medals} />
                    <div className="sns_wrap">
                        {links.map((link, index) => (
                            <button
                                key={index}
                                type="button"
                                className={link.code}
                                tip={this.getLinkName(link)}
                                onClick={this.handleLinkClick(link)}
                            >
                                <span>{link.code === "smr" ? <img src={link.image} alt="" /> : this.getLinkName(link)} </span>
                            </button>
                        ))}
                    </div>
                </section>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        authenticationReducer: state.authenticationReducer,
        bjInfo: state.stationReducer.bjInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeFavorite: () => {
            dispatch(storeFavorite());
        },
        destroyFavorite: () => {
            dispatch(destroyFavorite());
        },
        fetchStationInfo: (id) => {
            return dispatch(fetchStationInfo(id));
        },
        storeFavoritePush: () => {
            dispatch(storeFavoritePush());
        },
        destroyFavoritePush: () => {
            dispatch(destroyFavoritePush());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChannelArt);
